<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="ml-12" v-if="categories">
      <trac-back-button>New Count</trac-back-button>
      <div class="flex flex-row mt-12 mb-8 items-end justify-between">
        <div class="flex-col">
          <h2>Inventory Control</h2>
          <h3 class="font-bold mt-4 text-2xl ">Category(s) to Count</h3>
        </div>
        <div class="flex-col ">
          <div class="flex flex-row gap-5">
            <trac-button @button-clicked="clearAllCategories" v-if="this.selectedCategories.length === this.categories.length" class="uppercase">Clear All</trac-button>
            <trac-button @button-clicked="selectAllCategories" v-else class="uppercase">All Categories</trac-button>
            <trac-button @button-clicked="saveSelectedCategories" class="uppercase">confirm</trac-button>
          </div>
        </div>
      </div>
      <div>
        <div class="grid grid-cols-4 gap-5">
          <div
          @click="selectCategory(category)"
            v-for="(category, i) in sortedCategories"
            class="small-shadow bg-white rounded-lg card-gradient cursor-pointer"
            :class="selectedCategoriesNames.includes(category.name) ? 'cardGradient' : ''"
            :key="i"
          >
            <div class="flex flex-row items-center">
              <div
                class="rounded-full flex flex-column bg-accentLight mx-5 my-8 py-2 items-center"
              >
                <img class=" mx-auto" src="../../../assets/svg/Vector.svg" />
              </div>
              <ul class="flex-column mr-5 hover:text-white">
                <li class="flex-column font-normal text-sm capitalize">
                  {{ category.name }}
                </li>
                <li class="text-xs text-primaryGray font-bold">
                  {{ `${category.count} Product(s)` }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { GET_LOCAL_DB_DATA, SAVE_LOCAL_DB_DATA } from '../../../browser-db-config/localStorage';
import { eventBus } from "./../../../main";

export default {
  name: "CategoryCount",
  data() {
    return {
      isLoading: false,
      categories: null,
      stockCountData: null,
      selectedCategoriesNames: [],
      selectedCategories: [],
    };
  },
  computed: {
    sortedCategories() {
      return this.categories.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
  },
  async created() {
    this.isLoading = true;
    this.stockCountData = GET_LOCAL_DB_DATA('stock-count-data');
    if (this.stockCountData) {
      this.selectedCategories = this.stockCountData.selected_categories;
      this.selectedCategoriesNames = this.selectedCategories.map(cat => cat.name);
    }
    await this.fetchAllCategories();
    this.isLoading = false;
  },
  methods: {
    saveSelectedCategories() {
      this.stockCountData = { ...this.stockCountData, selected_categories: this.selectedCategories }
      SAVE_LOCAL_DB_DATA('stock-count-data', this.stockCountData);
      this.$router.back();
    },
    clearAllCategories() {
      this.selectedCategoriesNames = [];
      this.selectedCategories = [];
    },
    selectAllCategories() {
      this.selectedCategoriesNames = [];
      this.selectedCategories = [];
      this.categories.forEach(cat => {
        this.selectedCategoriesNames.push(cat.name);
        this.selectedCategories.push(cat);
      });
    },
    selectCategory(category) {
      if (this.selectedCategoriesNames.includes(category.name)) {
        this.selectedCategoriesNames = this.selectedCategoriesNames.filter(cat => cat !== category.name);
        this.selectedCategories = this.selectedCategories.filter(cat => cat.name !== category.name);
      } else {
        this.selectedCategoriesNames.push(category.name);
        this.selectedCategories.push(category);
      }
    },
    async fetchAllCategories() {
      await this.$store.dispatch("FETCH_ALL_CATEGORIES");
      const res = this.$store.getters["GET_ALL_CATEGORIES"];

      if (res.status) {
        const data = res.data || [{ name: "", count: 0 }];
        if (data.length > 1) {
          this.categories = data.slice(1, data.length);
        }
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
    },
  },
};
</script>

<style scoped>
.card-gradient:hover {
  background: linear-gradient(180deg, #dceaff 0%, #dceaff 100%);
}
.cardGradient {
  color: #fff;
  background: linear-gradient(180deg, #0143aa 0%, #003283 100%) !important;
}
</style>
